



























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { setQueryAllAttribute } from '@/utils/DomUtil';
import ResetPasswordApi from '@/services/api/ResetPassword';

@Component
class ResetPassword extends Vue {

    public pwShow1: boolean = false;
    public pwShow2: boolean = false;
    public password: string = '';
    public passwordVerify: string = '';
    public valid: boolean = true;
    public token: string = '';
    public submitted: boolean = false;
    public message: string = '';
    public successMessage: string;
    public errorMessageColor: string;
    public successMessageColor: string;
    public messageColor: string = '';
    public serverErrorMessage: string;
    public response: string = '';

    public rules = {
        required: (value: string) => !!value || 'required field',
        password: (value: string) => value.length >= 8 || 'At least 8 characters',
        whitespace: (value: string) => {
            const pattern = new RegExp(/^(\S+\s*\S+)+$/);
            return pattern.test(value) || 'Password should not contain leading or trailing whitespace';
        },

    };

    public constructor() {
        super();
        this.successMessage = 'Your password was successfully updated';
        this.errorMessageColor = 'red';
        this.successMessageColor = 'green';
        this.serverErrorMessage = 'server error';
    }

    public mounted() {
        setQueryAllAttribute('.v-icon', 'tabindex', '-1');
        this.token = decodeURIComponent(this.$route.query.token.toString());
    }

    public submit(): void {
        if ((this.$refs.resetPasswordForm as any).validate()) {
            this.response = 'processing';
            ResetPasswordApi.resetPassword({password: this.password, token: this.token})
                .then(response => {
                    if (response.data.success) {
                        this.response = 'received';
                        this.submitted = true;
                        this.message = this.successMessage;
                        this.messageColor = this.successMessageColor;
                    } else {
                        this.response = 'error';
                        this.message = response.data.error;
                        this.messageColor = this.errorMessageColor;
                    }
                })
                .catch(() => {
                    this.response = 'error';
                    this.message = this.serverErrorMessage;
                    this.messageColor = this.errorMessageColor;
                });
        }
    }
}

export default ResetPassword;
